var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"publicNav flex",class:[_vm.deviceInfo.type],style:({
			background: ("linear-gradient(90deg," + (_vm.currentSkin.topBg[0]) + " 0%," + (_vm.currentSkin.topBg[1]) + " 100%)"),
		})},[_c('div',{staticClass:"min-480 title flex",class:_vm.model},[(_vm.layoutMode == 'navFull')?_c('span',{style:({
					color: _vm.currentSkin ? _vm.currentSkin.topColor : '',
				})},[_c('span',{staticStyle:{"font-size":"30px","margin-left":"20px","letter-spacing":"2px"}},[_vm._v("北斗电子浮标管理平台")])]):_vm._e(),(_vm.showBreadcrumb)?_c('el-breadcrumb',{staticStyle:{"margin-left":"10px"},attrs:{"separator":"/"}},_vm._l((_vm.breadcrumb),function(item){return _c('el-breadcrumb-item',{key:item.name},[_c('em',{staticClass:"breadcrumbSpan",style:({
							color: _vm.currentSkin ? _vm.currentSkin.topColor : '',
						})},[_vm._v(" "+_vm._s(item.label)+" ")])])}),1):_vm._e()],1),(_vm.navMode != 'default')?_c('div',{staticClass:"min-480 topNav"},[_c('el-menu',{staticClass:"el-menu-demo",attrs:{"default-active":_vm.activeIndex,"mode":"horizontal","background-color":_vm.currentSkin.topBg[0],"text-color":"#ffffff","active-text-color":"#ffffff"},on:{"select":_vm.handleSelect}},_vm._l((_vm.nav),function(item){return _c('el-menu-item',{key:item.name,attrs:{"index":item.name}},[_vm._v(_vm._s(item.meta.title))])}),1)],1):_vm._e(),_c('div',{staticClass:"max-480 flex small"},[(_vm.deviceInfo.type !== 'PC')?_c('el-popover',{attrs:{"placement":"bottom","trigger":"click","popper-class":"menuPopup","visible-arrow":false,"offset":-48}},[_c('side-nav',{attrs:{"canSwitch":false}}),_c('div',{staticClass:"flex small-icon",class:{ flexd: _vm.isSideOpen },attrs:{"slot":"reference"},slot:"reference"},[_c('i',{staticClass:"el-icon-menu",style:({
							color: _vm.currentSkin ? _vm.currentSkin.topColor : '',
						})})])],1):_vm._e()],1),_c('div',{staticClass:"max-480 small-title",style:({
				color: _vm.currentSkin ? _vm.currentSkin.topColor : '',
			})},[_vm._v(" 北斗电子浮标管理平台 ")]),(!_vm.model)?_c('div',{staticClass:"account flex"},[_c('span',{staticClass:"account-sn"},[_vm._v(" "+_vm._s(_vm.account))]),_c('el-popover',{attrs:{"placement":"bottom-start","trigger":"click","popper-class":"select-popover"},on:{"show":_vm.toggleChangeIcon,"hide":_vm.toggleChangeIcon}},[_c('div',{staticClass:"account-select"},_vm._l((_vm.selects),function(item){return _c('div',{key:item.type,staticClass:"select-one flex",on:{"click":function($event){return _vm.handleClick(item)}}},[_c('img',{staticClass:"img",attrs:{"src":item.icon,"alt":""}}),_c('img',{staticClass:"img_act",attrs:{"src":item.actIcon,"alt":""}}),_c('span',[_vm._v(" "+_vm._s(item.label)+" ")])])}),0),_c('div',{staticClass:"flex account-info",attrs:{"slot":"reference"},slot:"reference"},[_c('span',{staticStyle:{"color":"#ffffff"}},[_vm._v(_vm._s(_vm.userName))]),_c('i',{staticClass:"iconfont icon-xiajiantou",class:{ iconOpen: _vm.isOpen },style:({
							color: _vm.currentSkin ? _vm.currentSkin.topColor : '',
						})})])])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }