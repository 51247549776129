<template>
	<div class="SidebarItem">
		<template v-for="item in navItem">
			<template v-if="!item.meta.hidden">
				<template v-if="!item.children">
					<el-menu-item :index="item.name" :key="item.name">
						<div class="flex" style="width:100%;">
							<em v-if="item.meta.icon" class="sideTitleImg" :style="`mask-image:url('${item.meta.icon}')`">
								<!-- <i :class="`iconfont ${item.meta.icon}`"></i> -->
								<!-- <img class="sideTitleImg" :title="$t(`routes.${item.name}`) || item.meta.title" :src="item.meta.icon"
									alt="" /> -->
							</em>

							<!-- <span slot="title">{{ item.meta.title }}</span> -->
							<item v-if="item.meta" :title="$t(`routes.${item.name}`) || item.meta.title" />
						</div>
					</el-menu-item>
				</template>
				<template v-else-if="item.children &&
					handleChildren(item.children, item.meta.oneChildrenHidden)
					">
					<el-menu-item :index="item.children[0].name" :key="item.children[0].name">
						<!-- <div class="flex"> -->
						<em class="sideTitleImg" :style="`mask-image:url('${item.children[0].meta.icon}')`">
							<!-- <i :class="`iconfont ${item.children[0].meta.icon}`"></i> -->
							<!-- <img class="sideTitleImg" :src="item.children[0].meta.icon"
								:title="$t(`routes.${item.children[0].name}`) || item.children[0].meta.title" alt="" /> -->
						</em>
						<!-- <span slot="title">{{ item.meta.title }}</span> -->
						<item v-if="item.children[0].meta" :title="$t(`routes.${item.children[0].name}`) ||
							item.children[0].meta.title
							" />
						<!-- </div> -->
					</el-menu-item>
				</template>
				<template v-else>
					<el-submenu :index="item.name" :key="item.name" popper-append-to-body>
						<template slot="title">
							<em :class="{ checked: parent == item.name }"  class="sideTitleImg" :style="`mask-image:url('${item.meta.icon}')`">
								<!-- <i :class="`iconfont ${item.meta.icon}`"></i> -->
								<!-- <img class="sideTitleImg" :src="item.meta.icon" :title="$t(`routes.${item.name}`) || item.meta.title"
									alt="" /> -->
							</em>
							<!-- <span :class="{ checked: parent == item.name }">{{
							item.meta.title
						}}</span> -->
							<item v-if="item.meta" :title="$t(`routes.${item.name}`) || item.meta.title" />
						</template>
						<sidebar-item :navItem="item.children" :parent="parent" :isOpen="isOpen"></sidebar-item>
					</el-submenu>
				</template>
			</template>
			<!-- {{ item.meta.title }} -->
		</template>
	</div>
</template>

<script>
import Item from "./Item.vue";

export default {
	components: { Item },
	name: "SidebarItem",
	props: {
		navItem: {
			type: Array,
			required: true,
			default: () => {
				return {};
			},
		},
		parent: {
			type: String,
			required: true,
			default: "",
		},
		isOpen: {
			type: Boolean,
			required: true,
			default: true,
		},
	},
	methods: {
		handleChildren(arr, show = true) {
			if (arr.length && arr.length != 1) {
				return (
					!arr.filter((f) => !f.meta.hidden).length ||
					arr.filter((f) => !f.meta.hidden).length == 1
				);
			} else {
				if (show) {
					return (
						!arr.filter((f) => !f.meta.hidden).length ||
						arr.filter((f) => !f.meta.hidden).length == 1
					);
				} else {
					return !arr.filter((f) => !f.meta.hidden).length;
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.SidebarItem {
	& em {
		// display: flex;
		// align-items: center;
		// height: 100%;
		// justify-content: center;
		// width: 100%;
		// max-width: 24px;
		display: inline-flex;
		align-items: center;

		& i {
			font-size: 20px;
			color: #ffffff;
		}
	}

	.sideTitleImg {
		width: 20px;
    height: 20px;
		background-color: rgb(38, 86, 40);
		-webkit-mask-size: 100%;
		-webkit-mask-repeat: no-repeat;
	}

	.checked {
		color: #ffffff;

		&::v-deep i {
			color: #ffffff;
		}
	}

	&::v-deep .el-submenu__title:hover {
		background-color: $nav_active_bg !important;
	}

	&::v-deep .el-menu-item:focus,
	.el-menu-item:hover {
		outline: 0;
		background-color: rgba($color: $nav_active_bg, $alpha: 0.6) !important;
	}

	&::v-deep .el-menu-item.is-active {
		background-color: $nav_active_bg;
		& em{
			background-color: #ffffff;
		}
	}

	//   &::v-deep .el-menu-vertical-demo:not(.el-menu--collapse) {
	//     width: 210px;
	//     min-height: 400px;
	//   }
	//   &::v-deep .el-submenu__title {
	//     padding: 0 16px !important;
	//   }
}
</style>