<template>
	<div
		class="publicNav flex"
		:class="[deviceInfo.type]"
		:style="{
			background: `linear-gradient(90deg,${currentSkin.topBg[0]} 0%,${currentSkin.topBg[1]} 100%)`,
		}"
	>
		<div class="min-480 title flex" :class="model">
			<span
				v-if="layoutMode == 'navFull'"
				:style="{
					color: currentSkin ? currentSkin.topColor : '',
				}"
			>
				<span style="font-size: 30px; margin-left: 20px; letter-spacing: 2px"
					>北斗电子浮标管理平台</span
				>
			</span>

			<el-breadcrumb
				separator="/"
				style="margin-left: 10px"
				v-if="showBreadcrumb"
			>
				<el-breadcrumb-item v-for="item in breadcrumb" :key="item.name">
					<em
						class="breadcrumbSpan"
						:style="{
							color: currentSkin ? currentSkin.topColor : '',
						}"
					>
						{{ item.label }}
					</em>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="min-480 topNav" v-if="navMode != 'default'">
			<el-menu
				:default-active="activeIndex"
				class="el-menu-demo"
				mode="horizontal"
				@select="handleSelect"
				:background-color="currentSkin.topBg[0]"
				text-color="#ffffff"
				active-text-color="#ffffff"
			>
				<el-menu-item v-for="item in nav" :key="item.name" :index="item.name">{{
					item.meta.title
				}}</el-menu-item>
			</el-menu>
		</div>

		<div class="max-480 flex small">
			<el-popover
				placement="bottom"
				trigger="click"
				popper-class="menuPopup"
				:visible-arrow="false"
				:offset="-48"
				v-if="deviceInfo.type !== 'PC'"
			>
				<side-nav :canSwitch="false"></side-nav>
				<div
					class="flex small-icon"
					slot="reference"
					:class="{ flexd: isSideOpen }"
				>
					<i
						class="el-icon-menu"
						:style="{
							color: currentSkin ? currentSkin.topColor : '',
						}"
					></i>
				</div>
			</el-popover>

			<!-- <el-popover
				placement="bottom"
				trigger="click"
				v-if="!model && deviceInfo.type == 'PC'"
			>
				<div class="langList">
					<h3>{{ $t("public.language") }}</h3>
					<div
						class="flex"
						:class="{ langListCheck: lang.isCheck }"
						v-for="lang in langEnum"
						:key="lang.type"
						@click="handleChangeLang(lang)"
					>
						<img :src="lang.icon" alt="" />
						<span>
							{{ lang.label }}
						</span>
					</div>
				</div>
				<div class="flex langBox" slot="reference">
					<img :src="showLang.icon" alt="" />
					<span class="omit-1">{{ showLang.label }}</span>
					<i class="el-icon-arrow-down"></i>
				</div>
			</el-popover> -->
		</div>
		<div
			class="max-480 small-title"
			:style="{
				color: currentSkin ? currentSkin.topColor : '',
			}"
		>
			北斗电子浮标管理平台
		</div>
		<div class="account flex" v-if="!model">
			<span class="account-sn"> {{ account }}</span>
			<!-- <img
				class="min-480 account-reveice"
				src="@/assets/images/public/reveice.png"
				alt=""
			/> -->
			<el-popover
				placement="bottom-start"
				trigger="click"
				popper-class="select-popover"
				@show="toggleChangeIcon"
				@hide="toggleChangeIcon"
			>
				<div class="account-select">
					<div
						class="select-one flex"
						v-for="item in selects"
						:key="item.type"
						@click="handleClick(item)"
					>
						<img :src="item.icon" class="img" alt="" />
						<img :src="item.actIcon" class="img_act" alt="" />
						<span>
							{{ item.label }}
						</span>
					</div>
				</div>
				<div class="flex account-info" slot="reference">
					<!-- <el-avatar
            class="min-480"
            size="medium"
            src="https://dummyimage.com/50x50/000/fff"
          ></el-avatar> -->
					<span style="color: #ffffff">{{ userName }}</span>
					<i
						class="iconfont icon-xiajiantou"
						:style="{
							color: currentSkin ? currentSkin.topColor : '',
						}"
						:class="{ iconOpen: isOpen }"
					></i>
				</div>
			</el-popover>
		</div>
	</div>
</template>

<script>
	import { delCookie } from "@/utils/cookie";
	import { loginOut, userType } from "@/api/index";
	import {
		title,
		navMode,
		layoutMode,
		langEnum,
		localLangFlag,
	} from "../../../config/project";
	import SideNav from "../sideNav";
	export default {
		name: "publicNav",
		components: { SideNav },
		props: {
			showBreadcrumb: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				baseBreadcrumb: {
					label: "首页",
					name: "/",
				},
				isOpen: false,
				selects: [
					{
						icon: require("@/assets/images/public/login.png"),
						actIcon: require("@/assets/images/public/login_act.png"),
						label: this.$t("public.login_out"),
						type: "logout",
					},
					// {
					// 	icon: require("@/assets/images/public/user.png"),
					// 	actIcon: require("@/assets/images/public/user_act.png"),
					// 	label: this.$t("public.user_manage"),
					// 	type: "user",
					// },
					// {
					// 	icon: require("@/assets/images/public/setting.png"),
					// 	actIcon: require("@/assets/images/public/setting_act.png"),
					// 	label: this.$t("public.equip_manage"),
					// 	type: "setting",
					// },
					// {
					// 	icon: require("@/assets/images/public/info.png"),
					// 	actIcon: require("@/assets/images/public/info_act.png"),
					// 	label: this.$t("public.equip_config"),
					// 	type: "info",
					// },
				],
				title,
				navMode,
				langEnum,
				layoutMode,
				activeIndex: "",
				userName: JSON.parse(localStorage.getItem("account")),
				// snName:'',
				account: JSON.parse(localStorage.getItem("username"))
					? JSON.parse(localStorage.getItem("username"))
					: "",
			};
		},
		mounted() {
			// 监听localhostStorage的数据变化，结合utils/tool.js配合使用
			// window.addEventListener('setItemEvent', function(e) {
			// 	const newdata = JSON.parse(e.newValue)
			// 	console.log('值改变了',newdata)
			// 	this.snName = newdata.sn
			// })
			this.getUserType();
		},
		computed: {
			breadcrumb() {
				let res = [];
				const { meta = {}, matched = [], name = "" } = this.$route;
				res.push({
					label: meta.title,
					name,
				});
				if (matched.length && meta.parant) {
					let one = matched.find((f) => f.name == meta.parant);
					res.push({
						label: one.meta.title,
						name: one.name,
					});
				}
				// res.push(this.baseBreadcrumb);
				return res.reverse();
			},
			isSideOpen() {
				return this.$store.getters.sideNavOpen;
			},
			currentSkin() {
				return this.$store.getters["skin/skinList"].find((f) => f.checked);
			},
			nav() {
				return this.$store.getters.menuList;
			},
			model() {
				return this.$store.getters.model;
			},
			deviceInfo() {
				return this.$store.getters.deviceInfo;
			},
			lang() {
				return this.$store.getters.lang;
			},
			currentSn() {
				return this.$store.getters.currentSn;
			},
			showLang() {
				return this.langEnum.find((f) => f.isCheck);
			},
		},
		watch: {
			$route: {
				handler(val) {
					if (this.navMode == "sideTop") {
						this.activeIndex = val.matched.filter((f) => f.path).length
							? val.matched.filter((f) => f.path)[0].name
							: "";
						this.$store.commit("permission/SET_TOP_LEVEL", this.activeIndex);
					}
					this.snName = JSON.parse(localStorage.getItem("receiverInfo"))
						? JSON.parse(localStorage.getItem("receiverInfo")).sn
						: "";
				},
				deep: true,
				immediate: true,
			},
			lang: {
				handler(val) {
					this.langEnum.forEach((item) => {
						if (item.type == val) {
							item.isCheck = true;
						} else {
							item.isCheck = false;
						}
					});
				},
				deep: true,
				immediate: true,
			},
			snName: {
				handler(val) {
					this.snName = val;
				},
			},
		},
		methods: {
			getUserType() {
				let userType = JSON.parse(localStorage.getItem("userType"));
				// if(userType !== 2){
				// 	this.selects = this.selects.filter(item => {
				// 		return item.type !=='user'
				// 	})
				// }
			},
			toggleOpenSide() {
				this.$store.commit("TOGGLE_SIDE_OPEN");
			},
			toggleShow() {
				this.$store.commit("skin/TOGGLE_SHOW_SKIN");
			},
			openScreen() {
				// this.$router.push({
				//   name: "member",
				// });
				const { origin = "" } = location;
				window.open(origin ? `${origin}/#/member` : "##");
			},
			toggleChangeIcon() {
				this.isOpen = !this.isOpen;
			},
			handleClick(e) {
				const { type = "" } = e || {};
				if (!type) {
					return;
				}
				if (type == "logout") {
					console.log(222222);
					this.$messageBox
						.confirm(this.$t("public.login_out_message"), this.$t("public.tip"), {
							confirmButtonText: this.$t("public.submit"),
							cancelButtonText: this.$t("public.cancel"),
							type: "warning",
						})
						.then((res) => {
							delCookie("token");
							delCookie("userMessObj");
							localStorage.removeItem("receiverInfo");
							localStorage.removeItem("account");
							localStorage.removeItem("userType");
							this.$store.commit("SET_LOGIN_STATUS", false);
							this.$router.replace({
								name: "login",
							});
							loginOut().then((res) => {
								localStorage.removeItem("token");
								// console.log(res)
							});
						})
						.catch((err) => {});
				} else if (type == "setting") {
					this.$router.push({
						name: "receiverList",
					});
				} else if (type == "user") {
					this.$router.push({
						name: "userList",
					});
				} else if (type == "info") {
					this.$router.push({
						name: "info",
					});
				}
			},
			handleSelect(e) {
				this.$router.push({
					name: e,
				});
				// console.log(e);
			},
			handleChangeLang(e) {
				if (e.type != this.showLang.type) {
					this.$store.dispatch("config/setLanguage", e.type);
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.publicNav {
		height: 60px;
		background: linear-gradient(90deg, #1353ab 0%, #032664 100%);
		justify-content: space-between;
		line-height: 60px;
		transition: all 0.4s;
		.title {
			height: 100%;
			& > span {
				height: 100%;
				display: inline-block;
				min-width: 210px;
				text-align: center;
				font-size: 18px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #f5f5f5;
			}
			& > i {
				cursor: pointer;
			}
			.breadcrumbSpan {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #deebff;
				cursor: pointer;
			}
		}
		.account {
			padding: 0 10px;
			& i {
				color: #fff;
				cursor: pointer;
				font-size: 24px;
				transition-duration: 0.5s;
				display: inline-block;
				& + i {
					margin-left: 20px;
				}
			}
			& > span {
				position: relative;
			}
			&-sn {
				font-size: 16px;
				color: #ffffff;
			}
			&-reveice {
				height: 46px;
				width: auto;
				margin: 0 10px;
			}
			&-info {
				margin-left: 20px;
				cursor: pointer;
				line-height: unset;
				& i {
					&:last-child {
						// margin-left: 12px;
						font-size: 16px;
					}
				}
			}
			.iconOpen {
				transform: rotate(180deg);
			}
		}
		.langBox {
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #ffffff;
			padding: 0 20px;
			cursor: pointer;
			& > img {
				height: 25px;
				width: auto;
			}
			& > span {
				margin: 0 10px;
			}
		}

		.topNav {
			flex: 1;
			height: 100%;
			overflow-x: auto;
			overflow-y: hidden;
			&::v-deep .el-menu-item {
				line-height: 54px;
				height: 54px;
			}
			&::v-deep .el-menu-item:not(.is-disabled):hover {
				background-color: rgba(17, 120, 229, 0.6) !important;
			}
			&::v-deep .el-menu-item.is-active {
				// border-color: #1353ab !important;
				background-color: #1178e5 !important;
			}
		}
		.small {
			flex: 1;
			line-height: 48px;
			height: 48px;
			position: relative;
			.langBox {
				padding: 0;
				font-size: 14px;
				& > span {
					margin: 0 4px;
					max-width: 28px;
					overflow: hidden;
				}
			}
			&-icon {
				justify-content: center;
				width: 48px;
				font-size: 18px;
				cursor: pointer;
				height: 48px;
			}
			.flexd {
				position: fixed;
				left: 0;
				top: 0;
				z-index: 50;
			}
			&-title {
				position: absolute;
				left: 50%;
				top: 0;
				transform: translateX(-50%);
				height: 48px;
				max-width: 50%;
				overflow: hidden;
				font-size: 16px;
				font-family: PingFangSC-Medium, PingFang SC;
			}
		}
	}
	.account-select {
		.select-one {
			padding: 6px 20px;
			transition-duration: 0.4s;
			position: relative;
			line-height: 24px;
			cursor: pointer;

			& > img {
				width: 16px;
				height: 16px;
			}
			.img {
				display: block;
			}
			.img_act {
				display: none;
			}
			&:hover {
				background-color: $main_color;
				color: #fff;
				border-radius: 3px;
				.img {
					display: none;
				}
				.img_act {
					display: block;
				}
			}
			& > span {
				flex: 1;
				text-align: center;
				padding-left: 8px;
			}
		}
	}
	.PC {
		background-image: url("../../../assets/images/public/top_bg.png") !important;
		background-position: center !important;
		background-size: 100% 100% !important;
		background-repeat: no-repeat !important;
	}
	.MOBIE{
		.account-info{
			margin-left: 10px;
		}
	}
</style>

<style lang="scss">
	.el-popper .popper__arrow {
		top: -7px !important;
	}

	.langList {
		min-width: 180px;
		& > * {
			height: 38px;
			line-height: 38px;
			z-index: 1;
		}
		& > div {
			color: #333;
			cursor: pointer;
		}
		& img {
			height: 25px;
			width: auto;
			margin-right: 10px;
			z-index: 1;
		}
		& span {
			z-index: 1;
		}
		.langListCheck {
			position: relative;

			color: #fff;
			&::before {
				content: "";
				position: absolute;
				width: calc(100% + 26px);
				background: #539d56;
				height: 100%;
				z-index: 0;
				left: -13px;
			}
		}
	}
	.select-popover {
		padding: 0;
	}
</style>
