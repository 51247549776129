<template>
	<div class="flex flag" :class="{ close: toggleOpen && !isOpen }" @click="goHome">
		<template v-if="logo">
			<!-- <img :src="logo" :alt="title" :title="title" /> -->
			<div>北斗电子浮标管理平台</div>
		</template>
		<template v-else>
			<span class="omit-1">
				{{ title || "标题" }}
			</span>
		</template>
	</div>
</template>

<script>
import { title, logo } from "../../../config/project";
import { isUrl } from "../../../utils/tool";
export default {
	props: {
		toggleOpen: {
			type: Boolean,
			default: true,
		},
	},
	name: "LogoBox",
	data() {
		return {
			title,
			logo,
		};
	},
	computed: {
		isOpen() {
			return this.$store.getters.sideNavOpen;
		},
		model() {
			return this.$store.getters.model;
		}
	},
	methods: {
		goHome() {
			if(!this.model){
				this.$router.push("/");
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.flag {
	width: 100%;
	height: 64px;
	line-height: 64px;
	min-width: 210px;
	justify-content: center;
	transition: 0.1s;
	cursor: pointer;

	&>span {
		text-align: center;
		font-size: 18px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #f5f5f5;
		overflow: hidden;
		max-width: 90%;
	}

	&>img {
		display: block;
		max-height: 90%;
		max-width: 90%;
		height: auto;
		width: auto;
	}
}

.close {
	width: 64px;
	height: 64px;
	min-width: 64px;
}
</style>